<template>
  <div id="mediumChainLipidsOil">
    <Header
      v-if="$route.params.flia == 'modulos-nutricionales'"
      imgUrl="header-modulosnutricionales.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_mn')"
    />
    <Header
      v-if="$route.params.flia == 'dieta-cetogenica'"
      imgUrl="header-dietacetogenica.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_dc')"
    />

    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/Botellas_Oil.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">
            <Sabor color="#e6e7e8" :texto="$t('productos.saborNeutro')" />

            <span class="mt-2">
              {{ $t("productos.presentacionBotella", { ml: "500 ml / 250" }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p
              class="pt-3"
              v-html="$t('productos.mediumChainLipidsOil.descripcion')"
            ></p>
          </div>

          <CaracteristicasNutricionalesImg
            :columnas="1"
            :caracteristicas="caracteristicas"
            img="icono-perfilMediumChainLipidsOil.png"
            ImgClass="icono-perfilMediumChainLipidsOil"
          />
        </div>
      </div>
    </div>
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TableMediumChainLipidsOil />
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
      </div>
    </div>

    <div class="container">
      <div class="row mt-4 txt-justificado">
        <div class="col-md-4 pe-4">
          <p>
            {{ $t("productos.modoPreparacion.texto") }}
          </p>
        </div>
        <div class="col-md-4 pe-4">
          <p>
            {{ $t("productos.mediumChainLipidsOil.modoPreparacion") }}
          </p>
        </div>
        <div class="col-md-4">
          <img
            src="@/assets/images/icons/lavadomanos.png"
            class="img-fluid h60 me-1"
          />
          <p class="align-self-center txt-celeste txt-iconos">
            {{ $t("productos.higiene") }}
          </p>
        </div>
      </div>
      <div class="row mb-4 text-center">
        <span
          ><em>{{ $t("productos.advertenciasTitulo") }}</em>
          {{ $t("productos.advertencias") }}</span
        >
      </div>
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados
      :productos="[
        'nutricion-clinica-pediatricos',
        'nutricion-clinica-adultos',
        'modulos-nutricionales',
        'dieta-cetogenica',
      ]"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sabor from "@/components/elementos/Sabor.vue";
import CaracteristicasNutricionalesImg from "@/components/CaracteristicasNutricionalesImg.vue";
import es from "@/locales/es.json";
import en from "@/locales/en.json";
import TableMediumChainLipidsOil from "@/components/tablas/TableMediumChainLipidsOil.vue";
// import ModoPreparacion from "@/components/ModoPreparacion.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";
// import DensidadCalorica from "@/components/DensidadCalorica.vue";

export default {
  name: "ProductoBase",
  components: {
    Header,
    Sabor,
    CaracteristicasNutricionalesImg,
    TableMediumChainLipidsOil,
    // ModoPreparacion,
    CardsRecomendados,
    // DensidadCalorica,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      caracteristicas: {
        en: en.productos.mediumChainLipidsOil.caracteristicas,
        es: es.productos.mediumChainLipidsOil.caracteristicas,
      },
      tablaEs: es.productos.sustentaNeutro.tablaPreparacion,
      tablaEn: en.productos.sustentaNeutro.tablaPreparacion,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
